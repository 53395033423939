import { commonTrack } from '@/common/tracking';

function itemClick({ itemId, location }) {
  commonTrack({
    name: 'item_click',
    detail: {
      item_id: itemId,
      location,
    },
  });
}

export default {
  itemClick,
};
