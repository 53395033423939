function serialize(detail) {
  const list = [];

  for (let key in detail) {
    if (detail[key] !== undefined) {
      list.push(`${key}:${detail[key]}`);
    }
  }

  return list.join(',');
}

export function commonTrack({ name, detail }) {
  if (window.gtag) {
    window.gtag('event', `goldstore/${name}`, {
      event_detail: detail && serialize(detail),
    });
  }
}
