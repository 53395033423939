import qs from 'qs';
import styles from './index.module.less';
import React from 'react';
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';
import { inject } from 'mobx-react';
import { CommonContext } from '../context';
import tracking from '../tracking';

function CoverWithSuperscript({ item }) {
  return (
    <div className={styles.cover}>
      <img className={styles.cover_main} src={item.cover} alt={item.productInfo.title} />
      {item.productInfo.superscript ? (
        <img className={styles.superscript} src={item.productInfo.superscript} alt='' />
      ) : null}
    </div>
  );
}

const Index = inject('area')(function ({ area }) {
  const {
    bannerList,
    listCategory,
    productList,
    userAvatarList,
    exchangeList,
    setSortId,
    sortId,
    setPoints,
    productPageNumber,
    productTotalPage,
    setProductPageNumber,
    productLoading,
  } = React.useContext(CommonContext);
  const {
    LANG,
    country: { code },
  } = area;
  const sortHeaderRef = React.useRef(null);
  const scoreList = React.useMemo(
    () => [
      [0, 500],
      [500, 1000],
      [1000, 2000],
      [2000, 3000],
      [3000, 5000],
      [5000, null],
    ],
    []
  );
  const [pointsIndex, setPointsIndex] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(0);
  // 初始化轮播图
  const initBannerList = React.useCallback(() => {
    const splide = new Splide('.banner_container_splide', {
      pauseOnHover: false,
      pauseOnFocus: false,
      autoplay: true,
      pagination: false,
      width: '1200px',
      height: '468px',
      type: 'loop',
      interval: 3000,
    });
    splide.on('move', (e) => {
      setActiveIndex(e);
    });
    splide.mount();

    // 监听第几个被点击了
    const splideProgress = document.querySelector(`.${styles.splide_progress}`);
    const points = splideProgress.querySelectorAll(`.${styles.point}`);
    function clickIndex(event) {
      const clickedElement = event.target;
      const clickedIndex = Array.from(points).indexOf(clickedElement);
      if (clickedIndex !== -1) {
        splide.go(clickedIndex);
      }
    }
    splideProgress.addEventListener('click', clickIndex);
    return () => {
      splideProgress.removeEventListener('click', clickIndex);
    };
  }, []);

  React.useEffect(() => {  
    initBannerList();
    // 位置移动
    sortHeaderRef.current.scrollTo({ left: 0 });

    // 获取 query 中的 category_id字段
    const search = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const categoryIdIndex = listCategory.map((item) => item.id + '').indexOf(search.category_id || null);
    if (categoryIdIndex > 0) {
      setSortId(+search.category_id);
      sortHeaderRef.current.scrollTo({ left: sortHeaderRef.current.children[categoryIdIndex - 1].offsetLeft - 40 });
    }
  }, [listCategory]);
  return (
    <div className={styles.container}>
      <div className={styles.banner_container}>
        <section className='splide banner_container_splide'>
          <div className='splide__track'>
            <ul className='splide__list'>
              {bannerList.map((item, index) => {
                return (
                  <a
                    key={index}
                    href={item.jumpLink}
                    target='_blank'
                    className={`${styles.img_container} splide__slide`}
                  >
                    <img src={item.imageUrlPc} />
                  </a>
                );
              })}
            </ul>
          </div>
          <div className={`splide__arrows ${styles.arrow_container}`}>
            <button className={`splide__arrow splide__arrow--prev ${styles.arrow_pre}`}>
              <img src='https://res.insta360.com/static/32105f25c74bc69009f45d54006ecd56/%E7%BC%96%E7%BB%84.svg' />
            </button>
            <button className={`splide__arrow splide__arrow--next ${styles.arrow_next}`}>
              <img src='https://res.insta360.com/static/f9dd630239ba4ac775876042aa6383b1/%E7%BC%96%E7%BB%84.svg' />
            </button>
          </div>
        </section>
        <div className={styles.splide_progress}>
          {bannerList.map((_, index) => {
            return (
              <div key={index} className={`${styles.point} ${activeIndex === index ? styles.active : null}`}></div>
            );
          })}
        </div>
      </div>
      <div className={styles.content_container}>
        <div className={styles.sort_container}>
          <div className={`${styles.sort_header}`}>
            <img
              className={styles.left_arrow}
              src='https://res.insta360.com/static/4a9cb2d03d6f0074d5fab89dbade6701/Frame%20624796.svg'
              onClick={() => {
                const index = listCategory.findIndex((e) => e.id === sortId);
                if (index === -1 || index === 0) return;
                setSortId(listCategory[index - 1].id);

                const transformX = sortHeaderRef.current.children[index - 1].offsetLeft - 40;
                sortHeaderRef.current.scrollTo({ left: transformX });
              }}
            />
            <div className={styles.item_container} ref={sortHeaderRef}>
              {listCategory.map((item, index) => {
                return (
                  <div
                    className={`${styles.item} ${sortId === item.id ? styles.active : ''}`}
                    key={index}
                    onClick={() => {
                      setPointsIndex(null);
                      setPoints(null);
                      setSortId(item.id);

                      const transformX = sortHeaderRef.current.children[index].offsetLeft - 40;
                      console.log('transformX', transformX);
                      sortHeaderRef.current.scrollTo({ left: transformX });
                    }}
                  >
                    {item.productCategoryInfo.name}
                  </div>
                );
              })}
            </div>
            <img
              className={styles.right_arrow}
              src='https://res.insta360.com/static/c0ff8641754698a5b86c57dcc9ea4520/Frame%20624916.svg'
              onClick={() => {
                const index = listCategory.findIndex((e) => e.id === sortId);
                if (index === -1 || index === listCategory.length - 1) return;
                setSortId(listCategory[index + 1].id);

                const transformX = sortHeaderRef.current.children[index + 1].offsetLeft - 40;
                sortHeaderRef.current.scrollTo({ left: transformX });
              }}
            />
          </div>
          <div className={styles.line}></div>
          <div className={styles.sort_content}>
            {sortId !== 'exchange' ? (
              <div className={styles.select_score}>
                <div className={styles.tip}>{LANG['index.integral_screening']}</div>
                <div className={styles.list}>
                  {scoreList.map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          if (pointsIndex === index) {
                            setPointsIndex(null);
                            setPoints(null);
                          } else {
                            setPointsIndex(index);
                            setPoints(scoreList[index]);
                          }
                        }}
                        className={`${styles.item} ${pointsIndex === index ? styles.active : ''}`}
                        key={index}
                      >
                        {index === scoreList.length - 1 ? LANG['index.more_points'] : item.join('-')}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {productLoading ? (
              <div className={styles.product_loading}>
                <img src='https://res.insta360.com/static/233285fd2b5abcace19db6f11cd5d7f1/Frame%20624940.svg' />
                <span>{LANG['index.loading']}</span>
              </div>
            ) : (
              <>
                {productList.length > 0 ? (
                  <div className={styles.list}>
                    {productList.map((item, index) => {
                      return (
                        <a
                          onClick={() => {
                            tracking.itemClick({
                              itemId: item.id,
                              location: code,
                            });
                          }}
                          href={`/product/${item.id}`}
                          target='_blank'
                          className={styles.item}
                          key={index}
                        >
                          <CoverWithSuperscript item={item} />
                          <div className={styles.description}>{item.productInfo.title}</div>
                          <div className={styles.score_container}>
                            <span>{item.points}</span>
                            <span>{LANG['common.label.gold']}</span>
                          </div>
                          <div className={styles.price_container}>
                            {item.productInfo.priceOld ? (
                              <>
                                {LANG['index.market_price']}
                                {item.productInfo.priceOld}
                              </>
                            ) : null}
                          </div>
                          <div className={styles.tip}>
                            {LANG['index.only_left']} {item.repertory} {LANG['index.sets']}
                          </div>
                        </a>
                      );
                    })}
                  </div>
                ) : (
                  <div className={styles.sort_empyt}>
                    <img src='https://res.insta360.com/static/bcd6f2204e9f7a9aaec7612f7b406ffd/%E7%8A%B6%E6%80%81%E5%9B%BE%E6%A0%87.png' />
                    <div className={styles.description}>{LANG['index.empyt_points']}</div>
                    <a
                      href='https://app.insta360.com/app/divide_points?&utm_source=oner&utm_medium=activities&utm_campaign=bonuschallenge'
                      target='_blank'
                      className={styles.btn_container}
                    >
                      {LANG['index.earn_points']}
                    </a>
                  </div>
                )}
              </>
            )}

            {productTotalPage > productPageNumber ? (
              <div
                onClick={() => {
                  setProductPageNumber(productPageNumber + 1);
                }}
                className={styles.loading_more}
              >
                {LANG['index.click_more']}
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.rank_container}>
          <div className={styles.rank_header}>
            <img
              className={styles.left_icon}
              src='https://res.insta360.com/static/57c1afe67766cd42f7f2335003dc89cc/%E5%BD%A2%E7%8A%B6%E7%BB%93%E5%90%88.png'
            />
            <img
              className={styles.right_icon}
              src='https://res.insta360.com/static/3266c660838f2082bded6830ac44a108/%E5%BD%A2%E7%8A%B6%E7%BB%93%E5%90%88%E5%A4%87%E4%BB%BD.png'
            />
            <div className={styles.title}>{LANG['index.everyone_cashing']}</div>
            <div className={styles.img_list}>
              {userAvatarList.map((item, index) => {
                return (
                  <img
                    key={index}
                    style={{
                      transform: `translateX(-${index * 6}px)`,
                    }}
                    src={item}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.rank_list}>
            {exchangeList.map((item, index) => {
              return (
                <a
                  onClick={() => {
                    tracking.itemClick({
                      itemId: item.id,
                      location: code,
                    });
                  }}
                  href={`/product/${item.id}`}
                  target='_blank'
                  className={styles.item}
                  key={item.id}
                >
                  <div className={`${styles.rank} ${styles['rank_' + index]}`}>{index + 1}</div>
                  <img src={item.cover} />
                  <div className={styles.item_content}>
                    <div className={styles.title}>{item.productInfo.title}</div>
                    <div className={styles.score}>
                      {item.points} {LANG['common.label.gold']}
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Index;
